import {ImageIcon} from "../../atoms/icon";
import classNames from "classnames";
import {Body} from "@/components/atoms/body";
import {FormattedText} from "@/components/formatted-text";

export function ParagraphDetailCard({paragraph, ...props}) {

  return (
    <div className={classNames(
      "paragraph--detail-card bg-white p-12 h-full rounded-md text-center flex flex-col",
      {"drop-shadow-sm": !paragraph?.field_detail_card_hide_shadow},
    )}>
      <div>
        {paragraph?.field_detail_card_icon?.name && (
          <div className="mb-2 h-10">
            <ImageIcon type={paragraph.field_detail_card_icon.name} color={"primary"} height={50} width={50} alt={paragraph?.field_detail_card_title} className={"m-auto object-contain max-h-full"}/>
          </div>
        )}
        <div className="font-bold text-primary text-2xl pb-3">
          {paragraph?.field_detail_card_title}
        </div>
      </div>
      {paragraph?.field_detail_card_body?.processed?.length ? (
        <Body className="prose text-center [&>ul]:text-left [&>ol]:text-left">
          <FormattedText processed={paragraph?.field_detail_card_body.processed} />
        </Body>
      ): <></>}
      {(!paragraph?.field_detail_card_body?.processed?.length && paragraph?.field_detail_card_description?.length) ? (
        <p className="leading-6 text-base text-center">{paragraph.field_detail_card_description}</p>
      ): <></>}
    </div>
  )
}
